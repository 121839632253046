import { Header } from './header';
import { useState } from 'react';
const images = require.context('./media/gallery', true);
const thumbnails = require.context('./media/gallery_thumbnails', true);

export const PhotoPortfolio = () => {
  const [currentImage, setCurrentImage] = useState(images.keys()[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getNextImage = (index) => {
    const nextIndex = getNextIndex(index);
    return images.keys()[nextIndex];
  }

  const getPrevImage = (index) => {
    const prevIndex = getPrevIndex(index);
    return images.keys()[prevIndex];
  }

  const getNextIndex = (index) => {
    return (index + 1) % images.keys().length;
  }

  const getPrevIndex = (index) => {
    return index === 0 ? images.keys().length - 1 : index - 1;
  }

  const imageModal = (
    <dialog id={`image-modal`} className="modal">
      <div className="modal-box rounded-sm p-0 max-w-[100%] sm:max-w-[80%] md:max-w-[65%] lg:max-w-[65%] xl:max-w-[65%] h-screen shadow-none bg-neutral">
        <div className="relative w-full h-full content-center">
          <img src={images(currentImage)} alt={currentIndex} className="object-contain max-w-full max-h-full mx-auto" />
          <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <div className="btn btn-circle btn-outline btn-primary" onClick={ () => {
              setCurrentImage(getPrevImage(currentIndex))
              setCurrentIndex(getPrevIndex(currentIndex))
            } }>❮</div>
            <div className="btn btn-circle btn-outline btn-primary" onClick={ () => {
              setCurrentImage(getNextImage(currentIndex))
              setCurrentIndex(getNextIndex(currentIndex))
            } }>❯</div>
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop bg-neutral opacity-30">
        <button>close</button>
      </form>
    </dialog>
  );

  return (
    <div className="h-screen flex flex-col">
      <Header currView={"portfolio"} />
      <div className="overflow-y-scroll">
        <div className="xl:w-2/3 lg:w-2/3 md:w-2/3 sm:w-2/3 w-4/5 mx-auto flex flex-col">
          <div className="grid grid-flow-row text-neutral grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 justify-between gap-4 my-4">
            {images.keys().map((image) => (
              <div key={image} className="card w-auto h-full bg-neutral shadow-md rounded-md hover:shadow-lg hover:scale-[102%]" onClick={ () => {
                setCurrentImage(image)
                setCurrentIndex(images.keys().indexOf(image))
                document.getElementById(`image-modal`).showModal()
              }}>
                <figure><img className="aspect-square object-cover rounded-md" src={thumbnails(image)} alt={image} /></figure>
              </div>
            ))}
            {imageModal}
          </div>
        </div>
      </div>
    </div>
  );
}