import { Header } from './header';
import projects from './project-data/projects.json';
const images = require.context('./media/software_portfolio', true);

const ProjectModal = ({ project }) => {
  return (
    <dialog id={`${project.title}-modal`} className="modal">
      <div className="modal-box rounded-box p-0 max-w-[100%] sm:max-w-[80%] md:max-w-[65%] lg:max-w-[65%] xl:max-w-[65%] h-screen shadow-none bg-neutral">
        <div className="relative w-full h-full">
          <div className="flex sm:flex-row md:flex-row flex-col mt-4">
            <div className="flex flex-col">
              <img src={images(`./${project.mainImage.src}`)} alt={project.mainImage.alt} className="sm:aspect-square md:aspect-square aspect-video object-cover max-w-full max-h-full mx-4 my-auto rounded-box" />
            </div>
            <div className="flex flex-col m-4">
              <h2 className="text-2xl text-primary mb-4">{project.title}</h2>
              {/* splits the string by newlines for better readability */}
              <p className="text-neutral-content">{project.description.split('\n').map((line) => (
                <>{line}<br /><br /></>
              ))}</p>
            </div>
          </div>
          {project.bodySections.map((section) => (
            <div className="flex sm:flex-row md:flex-row flex-col m-4">
              <div className={`flex flex-col m-4 mb-8 mx-auto ${section.images.length > 0 ? "xl:w-2/3 lg:w-2/3 w-full" : "w-full mr-4"}`}>
                <h3 className="text-lg text-primary mb-4">{section.title}</h3>
                {/* splits the string by newlines for better readability */}
                <p className="text-neutral-content">{section.body.split('\n').map((line) => (
                  <>{line}<br /><br /></>
                ))}</p>
              </div>
              <div className={`flex sm:flex-col md:flex-col flex-row mt-4 xl:mx-4 lg:mx-4 md:mx-4 mx-auto ${section.images.length > 0 ? "xl:w-1/3 lg:w-1/3 w-full" : "hidden"}`}>
                {/* image carousel */}
                <div className={`carousel rounded-box space-x-2 mx-auto my-auto w-full h-full ${section.images.length > 1 ? "p-4 bg-base-100" : ""}`}>
                  {section.images.map((image) => (
                    <div className={`carousel-item w-full ${section.images.indexOf(image) === 0 ? "pl-4" : ""} ${section.images.length <= 1 ? "pl-0" : ""}`}>
                      <img src={
                        images(`./${image.src}`)}
                        alt={image.alt}
                        className="rounded-box"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
          {project.conclusion !== "" && (
            <div className="flex flex-col m-4 mr-8 pb-8">
              <h3 className="text-lg text-primary mb-4">Conclusion</h3>
              {/* splits the string by newlines for better readability */}
              <p className="text-neutral-content">{project.conclusion.split('\n').map((line) => (
                <>{line}<br /><br /></>
              ))}</p>
            </div>
          )}
        </div>
      </div>
      <form method="dialog" className="modal-backdrop bg-neutral opacity-30">
        <button></button>
      </form>
    </dialog>
  );
}

const ProjectCard = ({ project }) => {
  // console.log(images.keys().indexOf(`./${project.mainImage.src}`));
  return (
    <div className="card lg:card-side xl:card-side 2xl:card-side bg-base-100 shadow-xl hover:scale-[102%] hover:shadow-2xl"
    onClick={() => document.getElementById(`${project.title}-modal`).showModal()}>
      <figure className="xl:w-1/3 xl:h-full lg:h-full lg:w-1/2 w-full h-1/2">
        <img
          src={images(`./${project.mainImage.src}`)}
          alt={project.mainImage.alt}
          className="xl:h-full lg:h-full w-full object-cover" />
      </figure>
      <div className="xl:w-2/3 lg:w-1/2 w-full card-body bg-base-300 xl:rounded-r-box lg:rounded-r-box xl:rounded-l-none lg:rounded-l-none rounded-b-box">
        <h2 className="card-title text-primary mb-4">{project.title}</h2>
        {/* splits the string by newlines for better readability */}
        <p className="text-neutral-content">{project.description.split('\n').map((line) => (
          <>{line}<br /><br /></>
        ))}</p>
        {<ProjectModal project={project} />}
      </div>
    </div>
  )
}

export const SoftwarePortfolio = () => {
  return (
    <div className="h-screen flex flex-col">
      <Header currView={"portfolio"} />
      <div className="overflow-y-scroll py-4">
        <div className="xl:w-2/3 lg:w-2/3 md:w-2/3 sm:w-2/3 w-4/5 mx-auto flex flex-col">
          <div className="grid grid-flow-row text-neutral grid-cols-1 justify-between gap-4">
            {projects.map((project) => (
              <ProjectCard project={project} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}